import React from 'react'
import entries from './../db/portfolio.json'
import { getTypeTag } from './widgets/tags.js'

export default class Portfolio extends React.Component {
  state = {
    entries: entries,
    detailsRange: 100,
    collaboratorFilter: '',
    cityFilter: '',
    typeFilter: ''
  }

  // BASE

  generateListByYears() {
    // get entries grouped per year
    const displayList = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014'].map(
      (year) => [year, this.getEntries(year)]
    )

    // generate list to render
    let toReturn = displayList.map(
      ([year, entries], index) =>
        entries.length > 0 && (
          <div key={year}>
            <table className='table-fixed border-collapse'>
              <caption className='caption-top sticky p-4 mt-8'>
                <h2 className='text-xl font-bold' id='year'>
                  {year}
                </h2>
                {entries.length > 1 ? <p className='text-sm text-slate-400'> Elements: {entries.length}</p> : ''}
              </caption>
              {this.displayTableHeaders()}
              <tbody>{entries.map((entry) => this.displayEntryTable(entry))}</tbody>
            </table>
          </div>
        )
    )

    // return
    return toReturn.some((e) => e) ? (
      toReturn
    ) : (
      <div>
        <h2>Empty results!</h2>
        <p>Try different filter settings</p>
      </div>
    )
  }

  // FILTERING

  getAllowedByDetails() {
    let allowedTypes = ['album']
    if (this.state.detailsRange < 2) {
      return []
    }
    if (this.state.detailsRange > 20) {
      allowedTypes.push('ep')
    }
    if (this.state.detailsRange > 60) {
      allowedTypes.push('single', 'edu')
    }
    return allowedTypes
  }

  getAllowedByCollaborator(entry, collaborator) {
    return entry.with && entry.with.length > 0 && entry.with.includes(collaborator)
  }

  getAllowedCity(entry, city) {
    return entry.city && entry.city.length > 0 && entry.city.includes(city)
  }

  getAllowedByType(entry, type) {
    return entry.type && entry.type.length > 0 && entry.type.includes(type)
  }

  getEntries(year) {
    let toReturn = this.state.entries[year]

    // details priority
    if (this.state.detailsRange < 100) {
      toReturn = toReturn
        // check if entry is allowed by lower bound
        .filter(
          (entry) =>
            this.getAllowedByDetails().includes(entry.type) ||
            (entry.fixed_priority_min && entry.fixed_priority_min <= this.state.detailsRange)
        )
        // check if entry is excluded by upper bound
        .filter((entry) => !(entry.fixed_priority_max && entry.fixed_priority_max >= this.state.detailsRange))
    }
    // collaborators filter
    if (this.state.collaboratorFilter) {
      toReturn = toReturn.filter((entry) => this.getAllowedByCollaborator(entry, this.state.collaboratorFilter))
    }

    // city filter
    if (this.state.cityFilter) {
      toReturn = toReturn.filter((entry) => this.getAllowedCity(entry, this.state.cityFilter))
    }

    // type filter
    if (this.state.typeFilter) {
      toReturn = toReturn.filter((entry) => this.getAllowedByType(entry, this.state.typeFilter))
    }

    return toReturn
  }

  // DISPLAYING

  displayTableHeaders() {
    return (
      <thead className=''>
        <tr>
          <th id='thLink'></th>
          <th it='thTag'></th>
          <th id='thTitle'>Title</th>
          <th id='thImg'></th>
          <th id='thPlace'>Place</th>
          <th id='thWith'>With</th>
          {/* <th>Link</th> */}
          {/* <th>Img</th> */}
        </tr>
      </thead>
    )
  }

  displayEntryTable(element) {
    return (
      <tr key={element.id || element.title}>
        <td className='tdLink'>{element.link && this.displayEntryLink(element)}</td>
        <td>{element.type && getTypeTag(element.type)}</td>
        <td className='tableTitle'>
          {element.description && element.description.length > 0 ? (
            <span>
              {' '}
              {/* <h3>{element.title}</h3> */}
              <details className='tdDesc'>
                <summary className='titleSummary'>
                  <h3>{element.title}</h3>
                  <span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12'
                      />
                    </svg>
                  </span>
                </summary>
                {element.description && <p className='content'>{element.description}</p>}
              </details>{' '}
            </span>
          ) : (
            <h3>{element.title}</h3>
          )}
        </td>
        <td className='tablePic'>
          {element.img ? (
            <img
              className=' object-contain inline min-h-0 max-h-20'
              src={require('./../db/imgs/' + element.img)}
              alt={element.title}
            />
          ) : null}
        </td>

        <td>{element.place && element.city ? element.place + ', ' + element.city : null}</td>
        <td>
          {element.with &&
            element.with.length > 0 &&
            element.with.map((collaborator) => <span>{collaborator + ' '}</span>)}
        </td>
      </tr>
    )
  }

  displayEntryLink(entry) {
    return entry.link ? (
      <div className='aDiv'>
        <a href={entry.link} target='_blank' rel='noreferrer'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
            />
          </svg>
        </a>
      </div>
    ) : null
  }

  displayDetailsFilter() {
    return (
      <div className='w-full'>
        <label className='block mb-2 text-sm font-medium'>Details range filter</label>
        <input
          type='range'
          min='0'
          max='100'
          value={this.state.detailsRange}
          className='p-2 w-full rounded-full cursor-pointer accent-emerald-700'
          onChange={(e) => this.setState({ detailsRange: e.target && e.target.value })}
        />
      </div>
    )
  }

  displayCollabFilter() {
    return (
      <div className='dropdownOption'>
        <label className='block mb-2 text-sm font-medium'>Select collaborator</label>
        <select
          id='selectCollab'
          className='p-2 text-sm text-gray-900 border border-gray-300 rounded-lg'
          onChange={(e) => this.setState({ collaboratorFilter: e.target && e.target.value })}
          defaultValue={''}>
          {/* TODO fill collaboratos - make it generate from file*/}
          <option value=''></option>
          <option disabled>Collaborators</option>
          <option value='Spalarnia'>Spalarnia</option>
          <option value='japanese.inhale'>japanese.inhale</option>
          <option disabled>Remixers</option>
          <option value='bela'>bela</option>
        </select>
        {this.displayClearButton({ collaboratorFilter: '' }, 'selectCollab')}{' '}
      </div>
    )
  }

  displayCityFilter() {
    return (
      <div className='dropdownOption'>
        <label className='block mb-2 text-sm font-medium'>Select city</label>
        <select
          id='selectCity'
          className='p-2 text-sm text-gray-900 border border-gray-300 rounded-lg '
          onChange={(e) => this.setState({ cityFilter: e.target && e.target.value })}
          defaultValue=''>
          {/* TODO fill cities */}
          <option value=''></option>
          <option disabled>Polska</option>
          <option value='Kraków'>Kraków</option>
          <option value='Wrocław'>Wrocław</option>
          <option disabled>Za granicą</option>
          <option value='Paris'>Paris</option>
          <option value='Ostrava'>Ostrava</option>
        </select>
        {this.displayClearButton({ cityFilter: '' }, 'selectCity')}{' '}
      </div>
    )
  }

  displayTypeFilter() {
    return (
      <div className='dropdownOption'>
        <label className='block mb-2 text-sm font-medium'>Select type</label>
        <select
          id='selectType'
          className='p-2 text-sm text-gray-900 border border-gray-300 rounded-lg '
          onChange={(e) => this.setState({ typeFilter: e.target && e.target.value })}
          defaultValue=''>
          {/* TODO fill types */}
          <option value=''></option>
          <option disabled>Releases</option>
          <option value='album'>Albums</option>
          <option value='ep'>EPs</option>
          <option value='single'>Singles</option>
          <option disabled>Other musical</option>
          <option value='live'>Lives</option>
          <option value='event'>Events</option>
          <option value='workshop'>Workshops</option>
          <option disabled>Art</option>
          <option value='exhibition'>Exhibitions</option>
          <option value='installation'>Installations</option>
          <option disabled>Others</option>
          <option value='website'>Websites</option>
          <option value='edu'>Education</option>
          <option value='interview'>Interviews</option>
        </select>
        {this.displayClearButton({ typeFilter: '' }, 'selectType')}
      </div>
    )
  }

  displayDisplayAllButton() {
    // TODO bug - jak sie kliknie to nie odznacza rzeczy w selectach
    return (
      <div>
        <button
          className='btn btn-error'
          onClick={() => this.setState({ detailsRange: 100, collaboratorFilter: '', cityFilter: '', typeFilter: '' })}>
          Remove filters
        </button>
      </div>
    )
  }

  displayClearButton(expectedState, elementId) {
    return (
      <button
        className='clearButton'
        onClick={() => {
          this.setState(expectedState)
          let el = document.getElementById(elementId)
          el.value = ''
        }}>
        clear selection
      </button>
    )
  }

  render() {
    return (
      <span>
        <button
          id='settingsButton'
          onClick={() => {
            document.getElementById('filterModal').toggleAttribute('open')
          }}>
          <span className='flex'>
            {/* Filter elements */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75'
              />
            </svg>
          </span>
        </button>
        <div className='make-it-100'>
          <details id='filterModal' className='myModal closed'>
            <summary style={{ display: 'none' }}></summary>
            <h1 className='bg-clip-text text-transparent bg-gradient-to-r from-emerald-950 to-emerald-800 pb-10'>
              CONTENT FILTERS
            </h1>
            <div className='filters-panel'>
              <div className='flex flex-row space-x-5'>{this.displayDetailsFilter()}</div>
              {this.displayCollabFilter()}
              {this.displayTypeFilter()}
              {this.displayCityFilter()}
              <div className='flex flex-row space-x-5 m-5 justify-center'>
                {this.displayDisplayAllButton()}
                <button className='btn' onClick={() => document.getElementById('filterModal').removeAttribute('open')}>
                  Hide
                </button>
              </div>
            </div>
          </details>
          <h1 className='text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-emerald-800 to-black text-center'>
            PORTFOLIO
          </h1>
          <div className=''>{this.generateListByYears()}</div>
          <div className='m-20 opacity-0'> . </div>
        </div>
      </span>
    )
  }
}
