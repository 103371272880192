import React from 'react'
import Portfolio from './components/Portfolio'
import AOS from 'aos'
import 'aos/dist/aos.css'
import inlineCSS from './db/styles.json'
import { themeChange } from 'theme-change'

// themeChange()

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.pages = [
      // <Blog />,
      // <About />,
      <Portfolio />
      // <Newsletter viewType='' />
    ]

    this.state = {
      viewPage: null,
      currTheme: false,
      disabledNewsletter: localStorage.disabledNewsletter === 'true'
    }
  }

  componentDidMount() {
    this.handleChangeTheme()
  }

  handleChangeTheme() {
    document.documentElement.classList.remove(localStorage.theme)
    localStorage.theme = localStorage.theme === 'dark' ? 'light' : 'dark'
    document.documentElement.classList.add(localStorage.theme)
    themeChange()
  }

  handleSwitch(add = true, forceNumber = null) {
    let newPage = this.state.viewPage

    // handle specific reuqest
    if (forceNumber !== null) {
      this.setState({ viewPage: forceNumber })
      return
    }
    // handle null - the beginning state
    if (newPage === null) {
      newPage = add ? 0 : this.pages.length - 1
    }
    // handle regular add
    else if (add) {
      newPage = newPage + 1
      newPage = newPage >= this.pages.length ? null : newPage
    }
    // handle regular minus
    else {
      newPage = newPage - 1
      newPage = newPage < 0 ? null : newPage % (this.pages.length - 1)
    }
    // set it
    console.log(newPage)
    this.setState({ viewPage: newPage })
  }

  getPage() {
    return <Portfolio />

    // if (this.state.viewPage !== null && this.state.viewPage <= this.pages.length) {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth' // for smoothly scrolling
    //   })
    //   return this.pages[this.state.viewPage]
    // }
    // return (
    //   <div className='make-it-100'>
    //     <p className='text-5xl font-bold m-4'>Cześć!</p>
    //     <p className='text-4xl font-bold m-4'>I'm ehh hahah</p>
    //     <p className='text-3xl font-bold m-4'>My name is Wojtek and I do music</p>
    //     <p className='text-2xl font-bold m-4'>Sometimes memes, blogging and workshops too</p>
    //     <p className='text-xl font-bold m-4'>Use this website to see it all</p>
    //   </div>
    // )
  }

  renderMenu() {
    const pagesStrings = this.pages.map((page) => {
      // get page name from variable and split it by its uppercase
      return page.type.name
        .match(/[A-Z][a-z]+|[0-9]+/g)
        .join(' ')
        .toLowerCase()
    })
    return pagesStrings.map((page, index) => {
      return (
        <button
          type='button'
          key={index}
          className={
            index === this.state.viewPage ? inlineCSS.buttonCSS + ' ' + inlineCSS.gradientCSS : inlineCSS.buttonCSS
          }
          onClick={() => this.handleSwitch(null, index)}>
          {page}
        </button>
      )
    })
  }

  getRandomAnimation() {
    const animations = [
      'fade',
      'fade-up',
      'fade-down',
      'fade-left',
      'fade-right',
      'fade-up-right',
      'fade-up-left',
      'fade-down-right',
      'fade-down-left',
      'flip-up',
      'flip-down',
      'flip-left',
      'flip-right',
      'slide-up',
      'slide-down',
      'slide-left',
      'slide-right',
      'zoom-in',
      'zoom-in-up',
      'zoom-in-down',
      'zoom-in-left',
      'zoom-in-right',
      'zoom-out',
      'zoom-out-up',
      'zoom-out-down',
      'zoom-out-left',
      'zoom-out-right'
    ]
    let ret = animations[Math.floor(Math.random() * animations.length)]
    // console.log(ret);
    return ret
  }

  renderBlurCircle() {
    const CIRCLE_RADIUS = 200
    const SHADOW_OFFSET = 30

    function getPosWithinViewport(maxPos) {
      return Math.floor(Math.random() * (maxPos - CIRCLE_RADIUS - SHADOW_OFFSET))
    }

    function randomWidthPos() {
      var actualWidth =
        document.body.clientWidth ||
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.offsetWidth

      return getPosWithinViewport(actualWidth)
    }

    function randomHeightPos() {
      var actualHeight =
        document.body.clientHeight ||
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.offsetHeight

      return getPosWithinViewport(actualHeight)
    }

    return (
      <div
        id='shadow-circle'
        data-aos={this.getRandomAnimation()}
        className='animate-pulse hover:scale-150'
        key={randomWidthPos() + randomHeightPos()}
        style={{
          marginLeft: randomWidthPos() + 'px',
          marginRight: randomWidthPos() + 'px',
          marginTop: randomHeightPos() + 'px',
          marginBottom: randomHeightPos() + 'px'
        }}></div>
    )
  }

  getRandomHeadText() {
    const headTexts = ['młody, nierozgarnięty', 'no-joke person', 'wojtek', '', 'muzyka']
    return (
      <span data-aos={this.getRandomAnimation()} id='head-text' className='tracking-wide'>
        {' '}
        {headTexts[Math.floor(Math.random() * headTexts.length)]}
      </span>
    )
  }

  disableBanner() {
    this.setState({ disabledNewsletter: true })
    localStorage.disabledNewsletter = true
  }

  render() {
    AOS.init({
      once: true,
      easing: 'ease-in-out'
    })

    return (
      <div id='main-element' data-aos='slide-left'>
        {this.getPage()}
      </div>
      // <div
      //   id='whole-app'
      //   //  className='dark:text-white dark:bg-zinc-900'
      // >
      //   {/* blurs */}
      //   {/* <div>
      //     {this.renderBlurCircle()}
      //     {this.renderBlurCircle()}
      //     {this.renderBlurCircle()}
      //     {this.renderBlurCircle()}
      //     {this.renderBlurCircle()}
      //     {this.renderBlurCircle()}
      //     {this.renderBlurCircle()}
      //   </div> */}

      //   {/* navbar */}
      //   {/* <div onClick={() => this.disableBanner()}>
      //     {this.state.disabledNewsletter ? '' : <Newsletter viewType='banner' />}
      //   </div> */}
      //   {/* <div id='overlay'>ehhhhhhhhhhhh</div> */}
      //   {/* <div id='overlay' style={{ marginLeft: 200, marginTop: 400 }}>
      //     hahahahahahah
      //   </div> */}
      //   {/* <h1 id='header' className='text-3xl'> */}
      //   {/* {this.getRandomHeadText()} */}
      //   {/* <span id='title'>ehh hahah</span> */}
      //   {/* <button
      //       type='button'
      //       className={inlineCSS.buttonCSS + ' ring-2 ring-emerald-900'}
      //       onClick={() => this.handleChangeTheme()}
      //       id='swipe'
      //       data-toggle-theme='dark,light'
      //       data-act-class='ACTIVECLASS'>
      //       Theme
      //     </button> */}
      //   {/* </h1> */}
      //   {/*
      //   <div id='navbar' className='flex justify-between sticky top-0 backdrop-blur z-50'>
      //     <button type='button' className={inlineCSS.buttonCSS} onClick={() => this.handleSwitch(false)} id='swipe'>
      //       <svg
      //         xmlns='http://www.w3.org/2000/svg'
      //         className='h-6 w-6'
      //         fill='none'
      //         viewBox='0 0 24 24'
      //         stroke='currentColor'
      //         strokeWidth={2}>
      //         <path strokeLinecap='round' strokeLinejoin='round' d='M15 19l-7-7 7-7' />
      //       </svg>
      //     </button>
      //     {this.renderMenu()}
      //     <button type='button' className={inlineCSS.buttonCSS} onClick={() => this.handleSwitch(true)} id='swipe'>
      //       <svg
      //         xmlns='http://www.w3.org/2000/svg'
      //         className='h-6 w-6'
      //         fill='none'
      //         viewBox='0 0 24 24'
      //         stroke='currentColor'
      //         strokeWidth={2}>
      //         <path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7' />
      //       </svg>
      //     </button>
      //   </div> */}

      //   {/* main page */}
      //   <div id='main-element' data-aos='slide-left'>
      //     {this.getPage()}
      //   </div>
      //   {/* standard footer with year, creator and links */}
      // </div>
    )
  }
}
