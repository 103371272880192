// BASE

function getBaseSVG() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ display: 'none' }}></svg>
  )
}

// TAGS

function getAlbumTag() {
  return (
    <div className={'tag bg-green-200 text-green-700'} title='album'>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' id='mdi-circle-slice-8' viewBox='0 0 24 24'>
        <path d='M12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4Z' />
      </svg>
      Album
    </div>
  )
}

function getEPTag() {
  return (
    <div className={'tag bg-blue-200 text-blue-700'} title='EP'>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' id='mdi-circle-slice-4' viewBox='0 0 24 24'>
        <path d='M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4M12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19V5Z' />
      </svg>
      EP
    </div>
  )
}

function getSingleTag() {
  return (
    <div className={'tag bg-red-200 text-red-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' id='mdi-circle-slice-1' viewBox='0 0 24 24'>
        <path d='M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4M12 5C13.93 5 15.68 5.78 16.95 7.05L12 12V5Z' />
      </svg>
      Single
    </div>
  )
}

function getEduTag() {
  return (
    <div className={'tag bg-cyan-200 text-cyan-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path d='M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z' />
        <path d='M13.06 15.473a48.45 48.45 0 0 1 7.666-3.282c.134 1.414.22 2.843.255 4.284a.75.75 0 0 1-.46.711 47.87 47.87 0 0 0-8.105 4.342.75.75 0 0 1-.832 0 47.87 47.87 0 0 0-8.104-4.342.75.75 0 0 1-.461-.71c.035-1.442.121-2.87.255-4.286.921.304 1.83.634 2.726.99v1.27a1.5 1.5 0 0 0-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.66a6.727 6.727 0 0 0 .551-1.607 1.5 1.5 0 0 0 .14-2.67v-.645a48.549 48.549 0 0 1 3.44 1.667 2.25 2.25 0 0 0 2.12 0Z' />
        <path d='M4.462 19.462c.42-.419.753-.89 1-1.395.453.214.902.435 1.347.662a6.742 6.742 0 0 1-1.286 1.794.75.75 0 0 1-1.06-1.06Z' />
      </svg>
      Education
    </div>
  )
}

function getLiveTag() {
  return (
    <div className={'tag bg-yellow-200 text-yellow-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M 12 0 C 9.6221929 0 7.6447019 1.7191745 7.125 4 L 6.8125 4 C 6.4125 4 6 4.4125 6 4.8125 L 6 6.1875 C 6 6.5875 6.4125 7 6.8125 7 L 7.5 7 C 7.8 7 8 6.8 8 6.5 L 8 5.1875 C 8 2.8439103 9.78 1 12 1 C 14.22 1 16 2.8439103 16 5.1875 L 16 6.5 C 16 6.8 16.2 7 16.5 7 L 17.1875 7 C 17.5875 7 18 6.5875 18 6.1875 L 18 4.8125 C 18 4.4125 17.5875 4 17.1875 4 L 16.875 4 C 16.355298 1.7191745 14.377807 0 12 0 z M 12 2 C 10.343146 2 9 3.3431458 9 5 C 9 6.6568542 10.343146 8 12 8 C 13.656854 8 15 6.6568542 15 5 C 15 3.3431458 13.656854 2 12 2 z M 12 9 C 8.6 9 7.60625 9.39375 6.90625 10.59375 L 2.8125 18 L 5.59375 18 L 5.90625 18 C 6.30625 17.3 7.19375 15.59375 8.09375 14.09375 L 8.09375 19.09375 L 9.3125 19.90625 C 9.3125 20.00625 9.30625 20 9.40625 20 L 14.59375 20 C 14.69375 20 14.7125 20.00625 14.8125 19.90625 L 16 19.09375 L 16 14.09375 C 16.8 15.59375 17.7875 17.3 18.1875 18 L 18.5 18 L 21.3125 18 L 17.1875 10.59375 C 16.3875 9.39375 15.4 9 12 9 z M 0.90625 19 C 0.40625 19 0 19.40625 0 19.90625 L 0 24 L 24 24 L 24.09375 24 L 24.09375 20 C 24.09375 19.5 23.6875 19.09375 23.1875 19.09375 L 18.5 19.09375 C 18.2 19.09375 17.8875 19.1125 17.6875 19.3125 L 15.40625 20.8125 C 15.10625 20.9125 14.89375 21 14.59375 21 L 9.40625 21 C 9.10625 21 8.8875 20.8875 8.6875 20.6875 L 6.40625 19.1875 C 6.20625 19.0875 5.89375 19 5.59375 19 L 0.90625 19 z' />
      </svg>
      Live
    </div>
  )
}

function getEventTag() {
  return (
    <div className={'tag bg-purple-200 text-purple-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path
          fill-rule='evenodd'
          d='M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z'
          clip-rule='evenodd'
        />
      </svg>
      Event
    </div>
  )
}

function getInterviewTag() {
  return (
    <div className={'tag bg-pink-200 text-pink-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path d='M8.25 4.5a3.75 3.75 0 1 1 7.5 0v8.25a3.75 3.75 0 1 1-7.5 0V4.5Z' />
        <path d='M6 10.5a.75.75 0 0 1 .75.75v1.5a5.25 5.25 0 1 0 10.5 0v-1.5a.75.75 0 0 1 1.5 0v1.5a6.751 6.751 0 0 1-6 6.709v2.291h3a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1 0-1.5h3v-2.291a6.751 6.751 0 0 1-6-6.709v-1.5A.75.75 0 0 1 6 10.5Z' />
      </svg>
      Interview
    </div>
  )
}

function getExhibitionTag() {
  return (
    <div className={'tag bg-amber-200 text-amber-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path d='M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' />
        <path
          fill-rule='evenodd'
          d='M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z'
          clip-rule='evenodd'
        />
      </svg>
      Exhibition
    </div>
  )
}

function getInstallationTag() {
  return (
    <div className={'tag bg-emerald-200 text-emerald-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path
          fill-rule='evenodd'
          d='M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z'
          clip-rule='evenodd'
        />
        <path d='M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z' />
      </svg>
      Installation
    </div>
  )
}

function getWebsiteTag() {
  return (
    <div className={'tag bg-sky-200 text-sky-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path
          fill-rule='evenodd'
          d='M14.447 3.026a.75.75 0 0 1 .527.921l-4.5 16.5a.75.75 0 0 1-1.448-.394l4.5-16.5a.75.75 0 0 1 .921-.527ZM16.72 6.22a.75.75 0 0 1 1.06 0l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06L21.44 12l-4.72-4.72a.75.75 0 0 1 0-1.06Zm-9.44 0a.75.75 0 0 1 0 1.06L2.56 12l4.72 4.72a.75.75 0 0 1-1.06 1.06L.97 12.53a.75.75 0 0 1 0-1.06l5.25-5.25a.75.75 0 0 1 1.06 0Z'
          clip-rule='evenodd'
        />
      </svg>
      Website
    </div>
  )
}

function getWorkshopTag() {
  return (
    <div className={'tag bg-orange-200 text-orange-700'}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path d='M11.25 5.337c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.036 1.007-1.875 2.25-1.875S15 2.34 15 3.375c0 .369-.128.713-.349 1.003-.215.283-.401.604-.401.959 0 .332.278.598.61.578 1.91-.114 3.79-.342 5.632-.676a.75.75 0 0 1 .878.645 49.17 49.17 0 0 1 .376 5.452.657.657 0 0 1-.66.664c-.354 0-.675-.186-.958-.401a1.647 1.647 0 0 0-1.003-.349c-1.035 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401.31 0 .557.262.534.571a48.774 48.774 0 0 1-.595 4.845.75.75 0 0 1-.61.61c-1.82.317-3.673.533-5.555.642a.58.58 0 0 1-.611-.581c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.035-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959a.641.641 0 0 1-.658.643 49.118 49.118 0 0 1-4.708-.36.75.75 0 0 1-.645-.878c.293-1.614.504-3.257.629-4.924A.53.53 0 0 0 5.337 15c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.036 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.369 0 .713.128 1.003.349.283.215.604.401.959.401a.656.656 0 0 0 .659-.663 47.703 47.703 0 0 0-.31-4.82.75.75 0 0 1 .83-.832c1.343.155 2.703.254 4.077.294a.64.64 0 0 0 .657-.642Z' />
      </svg>
      Workshop
    </div>
  )
}

// EXPORTS

export function getCollabTag(name) {
  return (
    <div className={'tag bg-slate-200 text-slate-700 '} key={name}>
      {getBaseSVG()}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z' />
      </svg>
      <span className='collaborator'> {name}</span>
    </div>
  )
}

export function getTypeTag(type) {
  switch (type) {
    case 'album':
      return getAlbumTag()
    case 'ep':
      return getEPTag()
    case 'single':
      return getSingleTag()
    case 'edu':
      return getEduTag()
    case 'live':
      return getLiveTag()
    case 'event':
      return getEventTag()
    case 'interview':
      return getInterviewTag()
    case 'exhibition':
      return getExhibitionTag()
    case 'installation':
      return getInstallationTag()
    case 'website':
      return getWebsiteTag()
    case 'workshop':
      return getWorkshopTag()
    default:
      return ''
  }
}
